<template>
  <!--登录页-->
  <div class="bg">
    <div class="title_div"></div>
   <div style="display:flex;align-items:center;justify-content: space-around;width: 60%;">
    <img
      src="@/assets/image/logo.png"
      @click="shuju"
      style="height: 50px; width: 46px; margin-top: 3vh"
    />
    <!-- <div @click="shuju"
    :class="{active:isActive ==1}"
      style="height: 50px; width: 70px; margin-top: 3vh">数据作战</div>
    
      <div  @click="fuW"
      :class="{active:isActive ==2}"
      style="height: 50px; width: 70px; margin-top: 3vh">
   服务中心
      </div> -->
    <!-- <img
    @click="fuW"
      src="@/assets/image/logo.png"
      style="height: 50px; width: 46px; margin-top: 3vh"
    /> -->
   </div>
   
    <div style="font-size: 18px; margin-top: 2vh">
      <!-- 欢迎{{ pagetype == 1 ? "登录" : "注册" }}博爱数据指挥室 -->
      数据作战室
    </div>
<div class="card_body2" v-if="show">
  <div style="display:flex;width: 100%;background-color: #D9EBF4;border-radius: 20px 20px 0 0;">
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 20px 15px 0 0;padding:4px 0; color: #2163E5; background-color: #fff;" @click="clickCard(true)" >博爱医药</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 0 20px 0 0 ;color: #fff; background-color: #D9EBF4;padding:4px 0;"  @click="zk()" >中科医院</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 0 20px 0 0 ;color: #fff; background-color: #D9EBF4;padding:4px 0;"  @click="fw()" >服务中心</div>
  </div>
  <div style="display:flex;width: 100%; background-color:#fff;" >
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;height:1.2vh;font-size:5vw;font-weight: 700;" :class="deliveryType==1?'card-active':''"> <div style="width:4vw;height:1vw;background:blue"></div></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:5vw;font-weight: 700;border-radius: 0 0 0 60px ;" :class="deliveryType==2?'card-active':'card-white'"></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:5vw;font-weight: 700;border-radius: 0 0 0 0 ;" :class="deliveryType==2?'card-active':'card-white'"></div>
  </div>
  <div style="display:flex;background-color: #D9EBF4;">
    <div style="width:100%;background:#fff;border-radius: 0 20px 20px 20px;" >
         <!--登录窗-->
    <div class="card_body">
     
  
 
     <div
         style="
           display: flex;
           justify-content: center;
           margin-bottom: 3vh;
           margin-top: 3vh;
         "
       >
         <div class="every_input">
           <img
             src="@/assets/icon/shoujihao.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="text"
             v-model="mobile"
             placeholder="手机号"
             class="login_input"
           />
         </div>
       </div>
       <div style="display: flex; justify-content: center; margin-bottom: 5vh">
         <div class="every_input">
           <img
             src="@/assets/icon/mima.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="password"
             v-model="password"
             placeholder="密码"
             class="login_input"
           />
         </div>
       </div>
       <div
         style="
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
         "
       >
         <div v-if="pagetype == 1" class="login_btn" @click="login_api()">
           登录
         </div>
         <div v-if="false" class="register_btn" @click="changePage(2)">注册</div>
         <div v-if="pagetype == 2" class="login_btn" @click="register_api()">
           确认注册
         </div>
         <div v-if="pagetype == 2" class="register_btn" @click="changePage(1)">
           返回登录
         </div>
         <div style="height: 20px"></div>
       </div>
 
 
 
      
     </div>
    </div>
    <!-- <div style="width:50%;height:54.2vh;background:#fff;border-radius: 0 20px 20px 0;"></div> -->
  </div>
</div>

<div class="card_body1" v-if="tShow">
  <div style="display:flex;width: 100%;">
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 20px 20px 0 0;  color: #fff; background-color: #D9EBF4;padding:4px 0;padding:5px 0;" @click="clickCard(true)" :class="deliveryType==1?'card-active':''" >博爱医药</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 20px 20px 0 0;  color: #fff; background-color: #D9EBF4;padding:4px 0;padding:5px 0;" @click="zk()" :class="deliveryType==1?'card-active':''" >中科医院</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 15px 20px 0 0 ;color: #2163E5;background-color: #fff;padding:4px 0;"  >服务中心</div>
  </div>
  <div style="display:flex;width: 100%; background-color: #fff;" >
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;height:1.2vh;font-size:5vw;font-weight: 700; background-color:#D9EBF4;border-radius: 0  0  0  0" ></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;height:1.2vh;font-size:5vw;font-weight: 700; background-color:#D9EBF4;border-radius: 0  0  60px 0" ></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:5vw;font-weight: 700;background-color: #fff;" ><div style="width:4vw;height:1vw;background:blue"></div></div>
  </div>
  <div style="display:flex;">
    <div style="width:100%;background:#fff;border-radius: 20px 0 20px 20px;" >
      <div class="card_body">
     
  
 
     <div
         style="
           display: flex;
           justify-content: center;
           margin-bottom: 3vh;
           margin-top: 3vh;
         "
       >
         <div class="every_input">
           <img
             src="@/assets/icon/shoujihao.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="text"
             v-model="mobile"
             placeholder="手机号"
             class="login_input"
           />
         </div>
       </div>
       <div style="display: flex; justify-content: center; margin-bottom: 5vh">
         <div class="every_input">
           <img
             src="@/assets/icon/mima.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="password"
             v-model="password"
             placeholder="密码"
             class="login_input"
           />
         </div>
       </div>
       <div
         style="
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
         "
       >
         <div v-if="pagetype == 1" class="login_btn" @click="login_api()">
           登录
         </div>
         <div v-if="false" class="register_btn" @click="changePage(2)">注册</div>
         <div v-if="pagetype == 2" class="login_btn" @click="register_api()">
           确认注册
         </div>
         <div v-if="pagetype == 2" class="register_btn" @click="changePage(1)">
           返回登录
         </div>
         <div style="height: 20px"></div>
       </div>
 
 
 
      
     </div>
    </div>
    <!-- <div style="width:50%;height:54.2vh;background:#fff;"></div> -->
  </div>
</div>

<div class="card_body1" v-if="tShow1">
  <div style="display:flex;width: 100%;">
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 20px 20px 0 0;  color: #fff; background-color: #D9EBF4;padding:4px 0;padding:5px 0;" @click="clickCard(true)" :class="deliveryType==1?'card-active':''" >博爱医药</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 10px 10px 0 0 ;color: #2163E5;background-color: #fff;padding:4px 0;"  @click="zk()">中科医院</div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:4vw;border-radius: 20px 20px 0 0;  color: #fff; background-color: #D9EBF4;padding:4px 0;padding:5px 0;"  :class="deliveryType==1?'card-active':''"  @click="fw()">服务中心</div>
 
  </div>
  <div style="display:flex;width: 100%; background-color: #fff;" >
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;height:1.2vh;font-size:5vw;font-weight: 700; background-color:#D9EBF4;border-radius: 0  0  50px  0" ></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;font-size:5vw;font-weight: 700;background-color: #fff;" ><div style="width:4vw;height:1vw;background:blue"></div></div>
 <div style="width: 50%;display:flex;justify-content: center;align-items: center;height:1.2vh;font-size:5vw;font-weight: 700; background-color:#D9EBF4;border-radius: 0  0  0 50px" ></div>

  </div>
  <div style="display:flex;">
    <div style="width:100%;background:#fff;border-radius: 20px 20px 20px 20px;" >
      <div class="card_body">
     
  
 
     <div
         style="
           display: flex;
           justify-content: center;
           margin-bottom: 3vh;
           margin-top: 3vh;
         "
       >
         <div class="every_input">
           <img
             src="@/assets/icon/shoujihao.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="text"
             v-model="mobile"
             placeholder="手机号"
             class="login_input"
           />
         </div>
       </div>
       <div style="display: flex; justify-content: center; margin-bottom: 5vh">
         <div class="every_input">
           <img
             src="@/assets/icon/mima.png"
             style="height: 20px; width: 20px; margin-right: 2vw"
           />
           <input
             type="password"
             v-model="password"
             placeholder="密码"
             class="login_input"
           />
         </div>
       </div>
       <div
         style="
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
         "
       >
         <div v-if="pagetype == 1" class="login_btn" @click="login_api()">
           登录
         </div>
         <div v-if="false" class="register_btn" @click="changePage(2)">注册</div>
         <div v-if="pagetype == 2" class="login_btn" @click="register_api()">
           确认注册
         </div>
         <div v-if="pagetype == 2" class="register_btn" @click="changePage(1)">
           返回登录
         </div>
         <div style="height: 20px"></div>
       </div>
 
 
 
      
     </div>
    </div>
    <!-- <div style="width:50%;height:54.2vh;background:#fff;"></div> -->
  </div>
</div>
    <!--登录窗-->
    <!-- <div class="card_body">
     
  
 
    <div
        style="
          display: flex;
          justify-content: center;
          margin-bottom: 3vh;
          margin-top: 3vh;
        "
      >
        <div class="every_input">
          <img
            src="@/assets/icon/shoujihao.png"
            style="height: 20px; width: 20px; margin-right: 2vw"
          />
          <input
            type="text"
            v-model="mobile"
            placeholder="手机号"
            class="login_input"
          />
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-bottom: 5vh">
        <div class="every_input">
          <img
            src="@/assets/icon/mima.png"
            style="height: 20px; width: 20px; margin-right: 2vw"
          />
          <input
            type="password"
            v-model="password"
            placeholder="密码"
            class="login_input"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div v-if="pagetype == 1" class="login_btn" @click="login_api()">
          登录1
        </div>
        <div v-if="false" class="register_btn" @click="changePage(2)">注册</div>
        <div v-if="pagetype == 2" class="login_btn" @click="register_api()">
          确认注册
        </div>
        <div v-if="pagetype == 2" class="register_btn" @click="changePage(1)">
          返回登录
        </div>
        <div style="height: 20px"></div>
      </div>



     
    </div> -->
    <!-- 下边字 -->
    <div style="margin-top: 10vh; display: flex; align-items: center;">
      <div class="bottom_line"></div>
      <div class="bottom_font">定战场</div>
      <div class="bottom_font">选精英</div>
      <div class="bottom_font">聚资源</div>
      <div class="bottom_font">打胜仗</div>
      <div class="bottom_line"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: "",
      password: "",
      pagetype: 1,
      loading: "",
      roleId : [],
      quanxain:1,
      type:'',
      isActive:1,
      deliveryType:1,
      tShow:false,
      show:true,
      tShow1:false,
    };
  },
  mounted() {
    if (this.$route.query.pagetype == 1) {
      this.showLoading();
      this.$message({
        message: "登录失效，请重新登录",
        type: "warning",
      });
      this.loading.close();
    }
  },
  methods: {
    zk(){
      this.tShow=false,
      this.show=false,
     this.tShow1=true
     this.type=11
    },
    fw(){ 
      this.tShow1=false
      this.show=false,
       this.tShow=true,
      this.type=10
    },
    clickCard(bool){
      this.tShow1=false,
      this.tShow=false,
      this.show=true
		   this.type=''
	  },
    shuju(){
      this.isActive=1
    this.type=''
    },
    fuW(){
      this.isActive=2
   this.type=10
    },
    //loading
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    //注册登录切换
    changePage(e) {
      this.password = "";
      this.mobile = "";
      this.pagetype = e;
    },
    //登录
    login_api() {
      this.$axios
        .post(this.$api.login_api, {
          mobile: this.mobile,
          password: this.password,
          type:this.type
        })
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res, "resaaa");
            localStorage.setItem("token", res.data.token);
            this.user_api()
            
            // window.location.reload()

          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
    },
    //角色权限
    user_api() {
      this.$axios.post(this.$api.user_api).then((res) => {
        this.roleId = res.data.data.roleId;
        console.log(res.data.data.type=='11',res.data.data.type)
        if(res.data.data.type =='11'){
          localStorage.setItem("user_info", JSON.stringify(res.data.data))
          
            this.quanxain = res.data.data.type;
            localStorage.setItem("quanxian",this.quanxain)
            this.$router.push("./service1?pagetype=0");
        }else{

          this.roleId.forEach((element) => {
          if (this.roleId.length === 1 && element === 3) {
            console.log(element, "element");
            this.quanxain = element;
            localStorage.setItem("quanxian",this.quanxain)
            this.$router.push("./point");
           
          }  else if (this.roleId.length === 1 && element === 7) {
            localStorage.setItem("user_info", JSON.stringify(res.data.data))
            console.log(element, "element");
            this.quanxain = element;
            localStorage.setItem("quanxian",this.quanxain)
            this.$router.push("./service?pagetype=0");
           
          }
          
          else  {
            this.quanxain = 1;
            localStorage.setItem("quanxian",this.quanxain)
            this.$router.push("./form");
          }
        });
        }
   

        console.log(this.roleId, "this.roleId");
      });
    },
    //注册
    register_api() {
      this.$axios
        .post(this.$api.register_api, {
          mobile: this.mobile,
          password: this.password,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.mobile = "";
            this.password = "";
            this.pagetype = 1;
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.bg {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle at 50%, #b0daf3, #ffffff);
  /* background-image: linear-gradient(-113deg,#FFFFFF, #4EC5FB); */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
}
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  line-height: 12vh;
  color: #ffffff;
  text-align: center;
  font-size: 4.6vw;
  width: 100vw;
}
.card_body {
  margin-top: 4vh;
  width: 86vw;
  /* border-radius: 20px; */
  /* -webkit-box-shadow: #d1d0d0 0px 0px 20px;
  -moz-box-shadow: #d1d0d0 0px 0px 20px;
  box-shadow: #d1d0d0 0px 0px 20px; */
  /* background-color: #ffffff; */
}
.card_body1 {
  margin-top: 4vh;
  width: 86vw;
  /* height: 60vh; */
  border-radius: 20px;
  -webkit-box-shadow: #d1d0d0 0px 0px 20px;
  -moz-box-shadow: #d1d0d0 0px 0px 20px;
  box-shadow: #d1d0d0 0px 0px 20px;
  background-color: #D9EBF4;

}
.card_body2 {
  margin-top: 4vh;
  width: 86vw;
  /* height: 60vh; */
  border-radius: 20px;
  -webkit-box-shadow: #d1d0d0 0px 0px 20px;
  -moz-box-shadow: #d1d0d0 0px 0px 20px;
  box-shadow: #d1d0d0 0px 0px 20px;
  background-color: #fff;

}
.card-active{
background-color: #fff;
color: red;
}
.card-white{
  background-color: #D9EBF4; 

}
.every_input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e0e0;
}
.login_btn {
  height: 40px;
  background-color: #1d60f0;
  border: 1px solid #1d60f0;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  width: 70vw;
  border-radius: 20px;
  margin-bottom: 1.6vh;
}
.register_btn {
  height: 40px;
  color: #afaeae;
  border: 1px solid #e2e0e0;
  text-align: center;
  line-height: 40px;
  width: 70vw;
  border-radius: 20px;
  margin-bottom: 3vh;
}
.login_input {
  border: none;
  width: 60vw;
  margin-top: 1vh;
  height: 30px;
  border-radius: 5px;
  padding-left: 3vw;
}
input::-webkit-input-placeholder {
  color: #afaeae;
}
.bottom_font {
  font-style: italic;
  font-weight: 800;
  color: #b7b5b5;
  font-size: 5vw;
  margin: 5px;
}
.bottom_line {
  background-color: #b7b5b5;
  width: 12vw;
  height: 1px;
}
.active{
  color: red;
}
</style>